import React, { useState, useContext, useEffect } from "react";
import "../../styles/form/FormPage.scss";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { formValidationSchema } from "../../models/ValidationSchema";
import { Trans, useTranslation } from "react-i18next";
import { GlobalContext } from "../../App";
import { FormContext } from "./Form";
import DatePicker, { setDefaultLocale } from "react-datepicker";
import { checkDuplicatedReg } from "../../api/apiManagement";
import "react-datepicker/dist/react-datepicker.css";
import {
  nameInputField,
  emailInputField,
  phoneInputField,
  mediaInputField,
  sexInputField,
  childInputField,
  petTypeInputField,
  petUrlInputField,
} from "../../data/InputFields";

const FormPage = () => {
  setDefaultLocale("es");
  const { isLoading, setIsLoading } = useContext(GlobalContext);
  const {
    userData,
    setUserData,
    setCurrentStep,
    currentStep,
    date0,
    setDate0,
    date1,
    setDate1,
    date2,
    setDate2,
  } = useContext(FormContext);
  const { t, i18n } = useTranslation();
  const {
    register,
    unregister,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      name: userData?.name,
      email: userData?.email,
      phone: userData?.phone,
      sex: userData?.sex,
      ig_name: userData?.ig_name,
      ig_url: userData?.ig_url,
      fb_name: userData?.fb_name,
      fb_url: userData?.fb_url,
      yt_url: userData?.yt_url,
      yt_name: userData?.yt_name,
      date_of_birth_0: userData?.date_of_birth_0,
      child_name_1: userData?.child_name_1,
      child_sex_0: userData?.child_sex_0,
      date_of_birth_1: userData?.date_of_birth_1,
      child_name_2: userData?.child_name_2,
      child_sex_1: userData?.child_sex_1,
      date_of_birth_2: userData?.date_of_birth_2,
      child_name_3: userData?.child_name_3,
      child_sex_2: userData?.child_sex_2,
      pet_type_1: userData?.pet_type_1,
      pet_instagram_1: userData?.pet_instagram_1,
      pet_type_2: userData?.pet_type_2,
      pet_instagram_2: userData?.pet_instagram_2,
      pet_type_3: userData?.pet_type_3,
      pet_instagram_3: userData?.pet_instagram_3,
    },
    resolver: yupResolver(formValidationSchema),
  });
  const [apiError, setApiError] = useState(0);

  const [childCount, setChildCount] = useState(1);
  const [petCount, setPetCount] = useState(1);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  useEffect(() => {
    console.log(userData);
  }, []);

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    // return [year, month, day].join("-");
    return [year, month].join("-");
  };

  const handleChildCountClick = () => {
    if (childCount < 3) {
      setChildCount((prev) => prev + 1);
    }
  };

  const handleDeleteChildCountClick = () => {
    if (childCount > 1) {
      setChildCount((prev) => prev - 1);
      for (let i = 0; i < childInputField.length; i++) {
        setValue(`${childInputField.value}${childCount - 1}`, ""); // Clear the field value of the last input field
      }
      console.log(childCount, "childCount");
      if (childCount === 3) {
        unregister("child_name_3");
        unregister("date_of_birth_2");
        unregister("child_sex_2");
        console.log("dele child 3");
      }
      if (childCount === 2) {
        unregister("child_name_2");
        unregister("date_of_birth_1");
        unregister("child_sex_1");
        console.log("dele child 2");
      }
    }
  };

  const generateBabyInputFields = () => {
    const inputFields = [];
    for (let i = 0; i < childCount; i++) {
      inputFields.push(
        <div key={i}>
          {i === 0 ? (
            <>
              {/* {Child_name} */}
              <div className="input-container">
                <div className="label">
                  <Trans i18nKey="form:form_child_nickname" />
                </div>
                <input
                  className="form-input"
                  type={nameInputField.type}
                  maxLength={nameInputField.maxLength}
                  {...register(`${nameInputField.child_value}${i + 1}`, {
                    required: true,
                    maxLength: nameInputField.maxLength,
                  })}
                ></input>
              </div>
              <div className="input-container">
                <div className="label">
                  <Trans i18nKey="form:form_child_birthday" />
                </div>
                <Controller
                  name={`${childInputField.value}${i}`}
                  control={control}
                  defaultValue={date0}
                  rules={{ required: true }}
                  render={() => (
                    <DatePicker
                      className="form-input"
                      selected={date0}
                      renderMonthContent={renderMonthContent}
                      showMonthYearPicker
                      dateFormat="MM/yyyy"
                      placeholderText="請選擇日期"
                      onChange={(val) => {
                        setDate0(val);
                        setValue(`${childInputField.value}${i}`, val);
                      }}
                      minDate={new Date(childInputField.min)}
                      maxDate={new Date(childInputField.max)}
                    />
                  )}
                />
                <div className="input-error-message">
                  {errors[`${childInputField.value}${i}`] &&
                  errors[`${childInputField.value}${i}`].type === "nullable" ? (
                    <Trans i18nKey="form:form_child_birthday_error" />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {/* {Child Sex} */}
              <div className="input-container">
                <div className="label">
                  <Trans i18nKey="form:form_child_sex" />
                </div>
                <Controller
                  control={control}
                  name={`${sexInputField[0].child_name}${i}`}
                  defaultValue={
                    userData?.child_sex_0 === "M"
                      ? {
                          value: sexInputField[1].value,
                        }
                      : userData?.child_sex_0 === "F"
                      ? {
                          value: sexInputField[2].value,
                        }
                      : {
                          value: sexInputField[0].value,
                        }
                  }
                  render={({ value, ref, field }) => (
                    <Select
                      {...field}
                      inputRef={ref}
                      name={`${sexInputField[0].child_name}${i}`}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={sexInputField}
                      defaultValue={
                        userData?.child_sex_0 === "M"
                          ? {
                              value: sexInputField[1].value,
                              label: sexInputField[1].label,
                            }
                          : userData?.child_sex_0 === "F"
                          ? {
                              value: sexInputField[2].value,
                              label: sexInputField[2].label,
                            }
                          : {
                              value: sexInputField[0].value,
                              label: sexInputField[0].label,
                            }
                      }
                      value={sexInputField.find((c) => c.value === value)}
                      onChange={(val) => field.onChange(val.value)}
                      isSearchable={false}
                    />
                  )}
                />
                <div className="input-error-message">
                  {errors[`${sexInputField[0].child_name}${i}`] &&
                  errors[`${sexInputField[0].child_name}${i}`].type ===
                    "nullable" ? (
                    <Trans i18nKey="form:form_child_sex_error" />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {i === 1 ? (
            <>
              {/* {Child_name} */}
              <div className="input-container">
                <div className="label">
                  <Trans i18nKey="form:form_child_nickname" />
                </div>
                <input
                  className="form-input"
                  type={nameInputField.type}
                  maxLength={nameInputField.maxLength}
                  {...register(`${nameInputField.child_value}${i + 1}`, {
                    required: true,
                    maxLength: nameInputField.maxLength,
                  })}
                ></input>
              </div>
              <div className="input-container">
                <div className="label">
                  <Trans i18nKey="form:form_child_birthday" />
                </div>
                <Controller
                  name={`${childInputField.value}${i}`}
                  control={control}
                  defaultValue={date1}
                  rules={{ required: true }}
                  render={() => (
                    <DatePicker
                      className="form-input"
                      selected={date1}
                      renderMonthContent={renderMonthContent}
                      showMonthYearPicker
                      dateFormat="MM/yyyy"
                      placeholderText="請選擇日期"
                      onChange={(val) => {
                        setDate1(val);
                        setValue(`${childInputField.value}${i}`, val);
                      }}
                      minDate={new Date(childInputField.min)}
                      maxDate={new Date(childInputField.max)}
                    />
                  )}
                />
                <div className="input-error-message">
                  {errors[`${childInputField.value}${i}`] &&
                  errors[`${childInputField.value}${i}`].type === "nullable" ? (
                    <Trans i18nKey="form:form_child_birthday_error" />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {/* {Child Sex} */}
              <div className="input-container">
                <div className="label">
                  <Trans i18nKey="form:form_child_sex" />
                </div>
                <Controller
                  control={control}
                  name={`${sexInputField[0].child_name}${i}`}
                  defaultValue={
                    userData?.child_sex_1 === "M"
                      ? {
                          value: sexInputField[1].value,
                        }
                      : userData?.child_sex_1 === "F"
                      ? {
                          value: sexInputField[2].value,
                        }
                      : {
                          value: sexInputField[0].value,
                        }
                  }
                  render={({ value, ref, field }) => (
                    <Select
                      {...field}
                      inputRef={ref}
                      name={`${sexInputField[0].child_name}${i}`}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={sexInputField}
                      defaultValue={
                        userData?.child_sex_1 === "M"
                          ? {
                              value: sexInputField[1].value,
                              label: sexInputField[1].label,
                            }
                          : userData?.child_sex_1 === "F"
                          ? {
                              value: sexInputField[2].value,
                              label: sexInputField[2].label,
                            }
                          : {
                              value: sexInputField[0].value,
                              label: sexInputField[0].label,
                            }
                      }
                      value={sexInputField.find((c) => c.value === value)}
                      onChange={(val) => field.onChange(val.value)}
                      isSearchable={false}
                    />
                  )}
                />
                <div className="input-error-message">
                  {errors[`${sexInputField[0].child_name}${i}`] &&
                  errors[`${sexInputField[0].child_name}${i}`].type ===
                    "nullable" ? (
                    <Trans i18nKey="form:form_child_sex_error" />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {i === 2 ? (
            <>
              {/* {Child_name} */}
              <div className="input-container">
                <div className="label">
                  <Trans i18nKey="form:form_child_nickname" />
                </div>
                <input
                  className="form-input"
                  type={nameInputField.type}
                  maxLength={nameInputField.maxLength}
                  {...register(`${nameInputField.child_value}${i + 1}`, {
                    required: true,
                    maxLength: nameInputField.maxLength,
                  })}
                ></input>
              </div>
              <div className="input-container">
                <div className="label">
                  <Trans i18nKey="form:form_child_birthday" />
                </div>
                <Controller
                  name={`${childInputField.value}${i}`}
                  control={control}
                  defaultValue={date2}
                  rules={{ required: true }}
                  render={() => (
                    <DatePicker
                      className="form-input"
                      selected={date2}
                      renderMonthContent={renderMonthContent}
                      showMonthYearPicker
                      dateFormat="MM/yyyy"
                      placeholderText="請選擇日期"
                      onChange={(val) => {
                        setDate2(val);
                        setValue(`${childInputField.value}${i}`, val);
                      }}
                      minDate={new Date(childInputField.min)}
                      maxDate={new Date(childInputField.max)}
                    />
                  )}
                />
                <div className="input-error-message">
                  {errors[`${childInputField.value}${i}`] &&
                  errors[`${childInputField.value}${i}`].type === "nullable" ? (
                    <Trans i18nKey="form:form_child_birthday_error" />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {/* {Child Sex} */}
              <div className="input-container">
                <div className="label">
                  <Trans i18nKey="form:form_child_sex" />
                </div>
                <Controller
                  control={control}
                  name={`${sexInputField[0].child_name}${i}`}
                  defaultValue={
                    userData?.child_sex_2 === "M"
                      ? {
                          value: sexInputField[1].value,
                        }
                      : userData?.child_sex_2 === "F"
                      ? {
                          value: sexInputField[2].value,
                        }
                      : {
                          value: sexInputField[0].value,
                        }
                  }
                  render={({ value, ref, field }) => (
                    <Select
                      {...field}
                      inputRef={ref}
                      name={`${sexInputField[0].child_name}${i}`}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={sexInputField}
                      defaultValue={
                        userData?.child_sex_2 === "M"
                          ? {
                              value: sexInputField[1].value,
                              label: sexInputField[1].label,
                            }
                          : userData?.child_sex_2 === "F"
                          ? {
                              value: sexInputField[2].value,
                              label: sexInputField[2].label,
                            }
                          : {
                              value: sexInputField[0].value,
                              label: sexInputField[0].label,
                            }
                      }
                      value={sexInputField.find((c) => c.value === value)}
                      onChange={(val) => field.onChange(val.value)}
                      isSearchable={false}
                    />
                  )}
                />
                <div className="input-error-message">
                  {errors[`${sexInputField[0].child_name}${i}`] &&
                  errors[`${sexInputField[0].child_name}${i}`].type ===
                    "nullable" ? (
                    <Trans i18nKey="form:form_child_sex_error" />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      );
    }
    return inputFields;
  };

  const handlePetCountClick = () => {
    if (petCount < 3) {
      setPetCount((prev) => prev + 1);
    }
  };

  const handleDeletePetCountClick = () => {
    if (petCount > 1) {
      setPetCount((prev) => prev - 1);
      for (let i = 0; i < petUrlInputField.length; i++) {
        setValue(`${petUrlInputField.value}${petCount - 1}`, ""); // Clear the field value of the last input field
      }
      console.log(petCount, "petCount");
      if (petCount === 3) {
        unregister("pet_instagram_3");
        unregister("pet_type_3");
        console.log("dele pet 3");
      }
      if (petCount === 2) {
        unregister("pet_instagram_2");
        unregister("pet_type_2");
        console.log("dele pet 2");
      }
    }
  };

  const generatePetInputFields = () => {
    const inputFields = [];
    for (let i = 0; i < petCount; i++) {
      inputFields.push(
        <div key={i}>
          {i === 0 ? (
            <>
              {/* {Pet Type} */}
              <div className="input-container">
                <div className="label">
                  <Trans i18nKey="form:form_pet_type" />
                </div>
                <Controller
                  control={control}
                  name={`${petTypeInputField[0].name}${i + 1}`}
                  defaultValue={
                    userData?.pet_type_1 === "貓貓"
                      ? {
                          value: petTypeInputField[1].value,
                        }
                      : userData?.pet_type_1 === "狗狗"
                      ? {
                          value: petTypeInputField[2].value,
                        }
                      : userData?.pet_type_1 === "其他"
                      ? {
                          value: petTypeInputField[3].value,
                        }
                      : {
                          value: petTypeInputField[0].value,
                        }
                  }
                  render={({ value, ref, field }) => (
                    <Select
                      {...field}
                      inputRef={ref}
                      name={`${petTypeInputField[0].name}${i + 1}`}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={petTypeInputField}
                      defaultValue={
                        userData?.pet_type_1 === "貓貓"
                          ? {
                              value: petTypeInputField[1].value,
                              label: petTypeInputField[1].label,
                            }
                          : userData?.pet_type_1 === "狗狗"
                          ? {
                              value: petTypeInputField[2].value,
                              label: petTypeInputField[2].label,
                            }
                          : userData?.pet_type_1 === "其他"
                          ? {
                              value: petTypeInputField[3].value,
                              label: petTypeInputField[3].label,
                            }
                          : {
                              value: petTypeInputField[0].value,
                              label: petTypeInputField[0].label,
                            }
                      }
                      value={petTypeInputField.find((c) => c.value === value)}
                      onChange={(val) => field.onChange(val.value)}
                      isSearchable={false}
                    />
                  )}
                />
              </div>
              {/* {pet url} */}
              <div className="input-container">
                <div className="label">
                  <Trans i18nKey="form:form_pet_url" />
                </div>
                <input
                  className="form-input"
                  type={`${petUrlInputField.type}`}
                  maxLength={`${petUrlInputField.maxLength}`}
                  {...register(`${petUrlInputField.value}${i + 1}`, {
                    required: true,
                    maxLength: `${petUrlInputField.maxLength}`,
                  })}
                ></input>
              </div>
            </>
          ) : (
            <></>
          )}
          {i === 1 ? (
            <>
              {/* {Pet Type} */}
              <div className="input-container">
                <div className="label">
                  <Trans i18nKey="form:form_pet_type" />
                </div>
                <Controller
                  control={control}
                  name={`${petTypeInputField[0].name}${i + 1}`}
                  defaultValue={
                    userData?.pet_type_2 === "貓貓"
                      ? {
                          value: petTypeInputField[1].value,
                        }
                      : userData?.pet_type_2 === "狗狗"
                      ? {
                          value: petTypeInputField[2].value,
                        }
                      : userData?.pet_type_2 === "其他"
                      ? {
                          value: petTypeInputField[3].value,
                        }
                      : {
                          value: petTypeInputField[0].value,
                        }
                  }
                  render={({ value, ref, field }) => (
                    <Select
                      {...field}
                      inputRef={ref}
                      name={`${petTypeInputField[0].name}${i + 1}`}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={petTypeInputField}
                      defaultValue={
                        userData?.pet_type_2 === "貓貓"
                          ? {
                              value: petTypeInputField[1].value,
                              label: petTypeInputField[1].label,
                            }
                          : userData?.pet_type_2 === "狗狗"
                          ? {
                              value: petTypeInputField[2].value,
                              label: petTypeInputField[2].label,
                            }
                          : userData?.pet_type_2 === "其他"
                          ? {
                              value: petTypeInputField[3].value,
                              label: petTypeInputField[3].label,
                            }
                          : {
                              value: petTypeInputField[0].value,
                              label: petTypeInputField[0].label,
                            }
                      }
                      value={petTypeInputField.find((c) => c.value === value)}
                      onChange={(val) => field.onChange(val.value)}
                      isSearchable={false}
                    />
                  )}
                />
              </div>
              {/* {pet url} */}
              <div className="input-container">
                <div className="label">
                  <Trans i18nKey="form:form_pet_url" />
                </div>
                <input
                  className="form-input"
                  type={`${petUrlInputField.type}`}
                  maxLength={`${petUrlInputField.maxLength}`}
                  {...register(`${petUrlInputField.value}${i + 1}`, {
                    required: true,
                    maxLength: `${petUrlInputField.maxLength}`,
                  })}
                ></input>
              </div>
            </>
          ) : (
            <></>
          )}
          {i === 2 ? (
            <>
              {/* {Pet Type} */}
              <div className="input-container">
                <div className="label">
                  <Trans i18nKey="form:form_pet_type" />
                </div>
                <Controller
                  control={control}
                  name={`${petTypeInputField[0].name}${i + 1}`}
                  defaultValue={
                    userData?.pet_type_3 === "貓貓"
                      ? {
                          value: petTypeInputField[1].value,
                        }
                      : userData?.pet_type_3 === "狗狗"
                      ? {
                          value: petTypeInputField[2].value,
                        }
                      : userData?.pet_type_3 === "其他"
                      ? {
                          value: petTypeInputField[3].value,
                        }
                      : {
                          value: petTypeInputField[0].value,
                        }
                  }
                  render={({ value, ref, field }) => (
                    <Select
                      {...field}
                      inputRef={ref}
                      name={`${petTypeInputField[0].name}${i + 1}`}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={petTypeInputField}
                      defaultValue={
                        userData?.pet_type_3 === "貓貓"
                          ? {
                              value: petTypeInputField[1].value,
                              label: petTypeInputField[1].label,
                            }
                          : userData?.pet_type_3 === "狗狗"
                          ? {
                              value: petTypeInputField[2].value,
                              label: petTypeInputField[2].label,
                            }
                          : userData?.pet_type_3 === "其他"
                          ? {
                              value: petTypeInputField[3].value,
                              label: petTypeInputField[3].label,
                            }
                          : {
                              value: petTypeInputField[0].value,
                              label: petTypeInputField[0].label,
                            }
                      }
                      value={petTypeInputField.find((c) => c.value === value)}
                      onChange={(val) => field.onChange(val.value)}
                      isSearchable={false}
                    />
                  )}
                />
              </div>
              {/* {pet url} */}
              <div className="input-container">
                <div className="label">
                  <Trans i18nKey="form:form_pet_url" />
                </div>
                <input
                  className="form-input"
                  type={`${petUrlInputField.type}`}
                  maxLength={`${petUrlInputField.maxLength}`}
                  {...register(`${petUrlInputField.value}${i + 1}`, {
                    required: true,
                    maxLength: `${petUrlInputField.maxLength}`,
                  })}
                ></input>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      );
    }
    return inputFields;
  };

  const onSubmitData = (d) => {
    console.log(d);
    console.log(formatDate(d.date_of_birth_0));
    var dataDate1;
    var dataDate2;
    if (date1 !== null) {
      dataDate1 = formatDate(date1);
    }

    if (date2 !== null) {
      dataDate2 = formatDate(date2);
    }
    setUserData({
      ...userData,
      name: d.name,
      email: d.email,
      mobile: d.phone,
      gender: d.sex,
      instagram_id: d.ig_name,
      instagram_url: d.ig_url,
      facebook_id: d.fb_name ?? "",
      facebook_url: d.fb_url ?? "",
      youtube_id: d.yt_url ?? "",
      youtube_url: d.yt_name ?? "",
      child_name_1: d.child_name_1 ?? "",
      child_dob_1: formatDate(d.date_of_birth_0) ?? "",
      child_gender_1: d.child_sex_0 ?? "",
      child_name_2: d.child_name_2 ?? "",
      child_dob_2: dataDate1 ?? "",
      child_gender_2: d?.child_sex_1 ?? "",
      child_name_3: d.child_name_3 ?? "",
      child_dob_3: dataDate2 ?? "",
      child_gender_3: d?.child_sex_2 ?? "",
      pet_type_1: d.pet_type_1 ?? "",
      pet_instagram_1: d.pet_instagram_1 ?? "",
      pet_type_2: d.pet_type_2 ?? "",
      pet_instagram_2: d.pet_instagram_2 ?? "",
      pet_type_3: d.pet_type_3 ?? "",
      pet_instagram_3: d.pet_instagram_3 ?? "",
      phone: d?.phone,
      sex: d?.sex,
      ig_name: d?.ig_name,
      ig_url: d?.ig_url,
      fb_name: d?.fb_name ?? "",
      fb_url: d?.fb_url ?? "",
      yt_url: d?.yt_url ?? "",
      yt_name: d?.yt_name ?? "",
      date_of_birth_0: d?.date_of_birth_0 ?? "",
      child_sex_0: d?.child_sex_0 ?? "",
      dataDate1: d?.date_of_birth_1 ?? "",
      child_sex_1: d?.child_sex_1 ?? "",
      dataDate2: d?.date_of_birth_2 ?? "",
      child_sex_2: d?.child_sex_2 ?? "",
    });

    setIsLoading(true);
    setApiError(0);
    checkDuplicatedReg(d.email, d.phone)
      .then((res) => {
        const currentStatus = res.data.status;
        console.log(res);
        console.log(userData);
        console.log(currentStatus);
        if (currentStatus === 200) {
          setCurrentStep(3);
        } else if (currentStatus === 203) {
          setApiError(3);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <div className="form-page-container">
      <div className="form-text">
        <Trans i18nKey="form:fill_text" />{" "}
        {userData?.member_type === 1 ? (
          <Trans i18nKey="form:JoyKreator" />
        ) : (
          <Trans i18nKey="form:businessKreator" />
        )}
      </div>
      <div className="selected-image-container">
        {userData?.member_type === 1 ? (
          <img
            className="selected-image"
            alt="selected-image"
            src="/assets/img/form_joykreator.png"
            draggable={false}
          />
        ) : (
          <img
            className="selected-image"
            alt="selected-image"
            src="/assets/img/form_businesskreator.png"
            draggable={false}
          />
        )}
      </div>
      <div className="form-container">
        {/* {Name} */}
        <div className="input-container">
          <div className="label">
            <Trans i18nKey="form:form_name" />
          </div>
          <input
            className="form-input"
            type={nameInputField.type}
            maxLength={nameInputField.maxLength}
            {...register(nameInputField.value, {
              required: true,
              maxLength: nameInputField.maxLength,
            })}
          ></input>
          <div className="input-error-message">
            {errors[nameInputField.value] &&
            errors[nameInputField.value].message === "fieldRequired" ? (
              <Trans i18nKey="form:form_name_error" />
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* {Email} */}
        <div className="input-container">
          <div className="label">
            <Trans i18nKey="form:form_mail" />
          </div>
          <input
            className="form-input"
            type={emailInputField.type}
            {...register(emailInputField.value, {
              required: true,
              pattern: emailInputField.pattern,
            })}
          ></input>
          <div className="input-error-message">
            {errors[emailInputField.value] &&
            errors[emailInputField.value].message === "fieldRequired" ? (
              <Trans i18nKey="form:form_mail_error" />
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* {Phone} */}
        <div className="input-container">
          <div className="label">
            <Trans i18nKey="form:form_mobile" />
          </div>
          <input
            className="form-input"
            type={phoneInputField.type}
            minLength={phoneInputField.minLength}
            maxLength={phoneInputField.maxLength}
            {...register(phoneInputField.value, {
              required: true,
              minLength: phoneInputField.minLength,
              maxLength: phoneInputField.maxLength,
              pattern: phoneInputField.pattern,
            })}
          ></input>
          <div className="input-error-message">
            {errors[phoneInputField.value] &&
            errors[phoneInputField.value].message === "fieldRequired" ? (
              <Trans i18nKey="form:form_mobile_error" />
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* {Sex} */}
        <div className="input-container">
          <div className="label">
            <Trans i18nKey="form:form_sex" />
          </div>
          <Controller
            control={control}
            // name="select.value"
            name={sexInputField[0].name}
            defaultValue={
              userData?.sex === "M"
                ? {
                    value: sexInputField[1].value,
                  }
                : userData?.sex === "F"
                ? {
                    value: sexInputField[2].value,
                  }
                : {
                    value: sexInputField[0].value,
                  }
            }
            render={({ value, ref, field }) => (
              <Select
                {...field}
                inputRef={ref}
                name={sexInputField[0].name}
                className="react-select-container"
                classNamePrefix="react-select"
                options={sexInputField}
                Copy
                defaultValue={
                  userData?.sex === "M"
                    ? {
                        value: sexInputField[1].value,
                        label: sexInputField[1].label,
                      }
                    : userData?.sex === "F"
                    ? {
                        value: sexInputField[2].value,
                        label: sexInputField[2].label,
                      }
                    : {
                        value: sexInputField[0].value,
                        label: sexInputField[0].label,
                      }
                }
                value={sexInputField.find((c) => c.value === value)}
                onChange={(val) => field.onChange(val.value)}
                isSearchable={false}
              />
            )}
          />
          <div className="input-error-message">
            {errors[sexInputField[0].name] &&
            (errors[sexInputField[0].name].type === "nullable" ||
              errors[sexInputField[0].name].type === "typeError") ? (
              <Trans i18nKey="form:form_sex_error" />
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* {ig name} */}
        <div className="input-container">
          <div className="label">
            <Trans i18nKey="form:form_ig_name" />
          </div>
          <input
            className="form-input"
            type={mediaInputField.type}
            maxLength={mediaInputField.maxLength}
            {...register(mediaInputField.ig_name_value, {
              required: true,
              maxLength: mediaInputField.maxLength,
            })}
          ></input>
          <div className="input-error-message">
            {errors[mediaInputField.ig_name_value] &&
            errors[mediaInputField.ig_name_value].message ===
              "fieldRequired" ? (
              <Trans i18nKey="form:form_ig_name_error" />
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* {ig url} */}
        <div className="input-container">
          <div className="label">
            <Trans i18nKey="form:form_ig_url" />
          </div>
          <input
            className="form-input"
            type={mediaInputField.type}
            maxLength={mediaInputField.maxLength}
            {...register(mediaInputField.ig_url_value, {
              required: true,
              maxLength: mediaInputField.maxLength,
            })}
          ></input>
          <div className="input-error-message">
            {errors[mediaInputField.ig_url_value] &&
            errors[mediaInputField.ig_url_value].message === "fieldRequired" ? (
              <Trans i18nKey="form:form_ig_url_error" />
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* {fb name} */}
        <div className="input-container">
          <div className="label">
            <Trans i18nKey="form:form_fb_name" />
          </div>
          <input
            className="form-input"
            type={mediaInputField.type}
            maxLength={mediaInputField.maxLength}
            {...register(mediaInputField.fb_name_value, {
              required: true,
              maxLength: mediaInputField.maxLength,
            })}
          ></input>
          <div className="input-error-message">
            {errors[mediaInputField.fb_name_value] &&
            errors[mediaInputField.fb_name_value].message ===
              "fieldRequired" ? (
              <Trans i18nKey="form:form_fb_name_error" />
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* {fb url} */}
        <div className="input-container">
          <div className="label">
            <Trans i18nKey="form:form_fb_url" />
          </div>
          <input
            className="form-input"
            type={mediaInputField.type}
            maxLength={mediaInputField.maxLength}
            {...register(mediaInputField.fb_url_value, {
              required: true,
              maxLength: mediaInputField.maxLength,
            })}
          ></input>
          <div className="input-error-message">
            {errors[mediaInputField.fb_url_value] &&
            errors[mediaInputField.fb_url_value].message === "fieldRequired" ? (
              <Trans i18nKey="form:form_fb_url_error" />
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* {yt name} */}
        <div className="input-container">
          <div className="label">
            <Trans i18nKey="form:form_yt_name" />
          </div>
          <input
            className="form-input"
            type={mediaInputField.type}
            maxLength={mediaInputField.maxLength}
            {...register(mediaInputField.yt_name_value, {
              required: true,
              maxLength: mediaInputField.maxLength,
            })}
          ></input>
          <div className="input-error-message">
            {errors[mediaInputField.yt_name_value] &&
            errors[mediaInputField.yt_name_value].message ===
              "fieldRequired" ? (
              <Trans i18nKey="form:form_fb_name_error" />
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* {yt url} */}
        <div className="input-container">
          <div className="label">
            <Trans i18nKey="form:form_yt_url" />
          </div>
          <input
            className="form-input"
            type={mediaInputField.type}
            maxLength={mediaInputField.maxLength}
            {...register(mediaInputField.yt_url_valie, {
              required: true,
              maxLength: mediaInputField.maxLength,
            })}
          ></input>
          <div className="input-error-message">
            {errors[mediaInputField.yt_url_valie] &&
            errors[mediaInputField.yt_url_valie].message === "fieldRequired" ? (
              <Trans i18nKey="form:form_yt_url_error" />
            ) : (
              <></>
            )}
          </div>
        </div>
        {/*Child details*/}
        {generateBabyInputFields()}
        {childCount > 1 ? (
          <div
            className="delete-button"
            type="button"
            onClick={handleDeleteChildCountClick}
          >
            <Trans i18nKey="form:form_child_delete" />
          </div>
        ) : (
          <></>
        )}{" "}
        <br></br>
        <button
          className={
            childCount < 3 ? "input-button" : "input-button disable-button"
          }
          type="button"
          onClick={() => handleChildCountClick()}
          disabled={childCount < 3 ? false : true}
        >
          <Trans i18nKey="form:form_child_add" />
        </button>
        <br></br>
        <br></br>
        {/*Pet details*/}
        {generatePetInputFields()}
        {petCount > 1 ? (
          <div
            className="delete-button"
            type="button"
            onClick={handleDeletePetCountClick}
          >
            <Trans i18nKey="form:form_pet_delete" />
          </div>
        ) : (
          <></>
        )}
        <br></br>
        <button
          className={
            petCount < 3 ? "input-button" : "input-button disable-button"
          }
          type="button"
          onClick={() => handlePetCountClick()}
          disabled={petCount < 3 ? false : true}
        >
          <Trans i18nKey="form:form_pet_add" />
        </button>
        <br></br>
        <br></br>
        {/*Submit button*/}
        <button
          className="input-button"
          type="button"
          onClick={handleSubmit(onSubmitData)}
        >
          <Trans i18nKey="form:Next" />
        </button>
        <span className="error-message">
          {apiError === 3 ? <Trans i18nKey="form:api_error_3" /> : <></>}
        </span>
        <br></br> <br></br>
        <button
          className="input-button"
          type="button"
          onClick={() => setCurrentStep(1)}
        >
          <Trans i18nKey="form:back_2" />
        </button>
      </div>
    </div>
  );
};

export default FormPage;
