import React, { useState, createContext } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.scss";
import Loading from "./components/Loading";
import Form from "./pages/form/Form";
import Enquire from "./pages/enquire/Enquire";
export const GlobalContext = createContext();

const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [goEnquire, setGoEnquire] = useState(false);
  const dataValue = {
    isLoading,
    setIsLoading,
    goEnquire,
    setGoEnquire,
  };

  return (
    <GlobalContext.Provider value={dataValue}>
      {isLoading ? <Loading /> : <></>}
      <div className="App">
        <div className="logo-container">
          <img className="logo" alt="logo" src="/assets/img/logo.png" />
        </div>
        <Form />
        {/* <Routes>
          <Route index path="/" element={<Form />} />
          <Route index path="/enquire" element={<Enquire />} />
        </Routes> */}
      </div>
    </GlobalContext.Provider>
  );
};

export default App;
