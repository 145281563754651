import React from "react";
import "../../styles/form/ThankYouPage.scss";
import { Trans, useTranslation } from "react-i18next";

const ThankYouPage = () => {
  return (
    <div className="thank-you-page-container">
      <div className="thank-you-text">
        <Trans i18nKey="form:thank_you_message_point" />
      </div>
    </div>
  );
};

export default ThankYouPage;
