export const codeInputField = {
  value: "code",
  type: "text",
  minLength: 8,
  maxLength: 8,
};

export const nameInputField = {
  value: "name",
  child_value: "child_name_",
  type: "text",
  maxLength: 100,
};

export const emailInputField = {
  value: "email",
  type: "email",
  pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$/,
};

export const phoneInputField = {
  value: "phone",
  type: "phone",
  minLength: 8,
  maxLength: 8,
  pattern: /^[4-9]([0-9]{7})$/,
};

export const mediaInputField = {
  type: "text",
  ig_name_value: "ig_name",
  ig_url_value: "ig_url",
  fb_name_value: "fb_name",
  fb_url_value: "fb_url",
  yt_name_value: "yt_name",
  yt_url_valie: "yt_url",
  maxLength: 250,
};

export const sexInputField = [
  {
    value: null,
    name: "sex",
    child_name: "child_sex_",
    label: "請選擇",
    en_label: "Please select sex",
  },
  {
    value: "M",
    name: "sex",
    child_name: "child_sex_",
    label: "男",
    en_label: "Man",
  },
  {
    value: "F",
    name: "sex",
    child_name: "child_sex_",
    label: "女",
    en_label: "Woman",
  },
];

export const childInputField = {
  label: "出生日期 / 預產期",
  label2: "寶寶出生日期/預產期需於2022年1月31日至2025年08月31日",
  value: "date_of_birth_",
  type: "date",
  min: "2022-01-31",
  max: "2025-08-31",
  error: "請選擇寶寶的出生日期 / 預產期。",
};

export const petTypeInputField = [
  {
    value: null,
    name: "pet_type_",
    label: "請選擇",
  },
  {
    value: "貓貓",
    name: "pet_type_",
    label: "貓貓",
  },
  {
    value: "狗狗",
    name: "pet_type_",
    label: "狗狗",
  },
  {
    value: "其他",
    name: "pet_type_",
    label: "其他",
  },
];

export const petUrlInputField = {
  value: "pet_instagram_",
  type: "text",
  maxLength: 100,
};

export const questionInputField = [
  {
    label: "A",
    value: "A",
  },
  {
    label: "B",
    value: "B",
  },
  { label: "C", value: "C" },
  { label: "D", value: "D" },
  { label: "E", value: "E" },
  { label: "F", value: "F" },
  { label: "G", value: "G" },
  { label: "H", value: "H" },
  { label: "I", value: "I" },
  { label: "J", value: "J" },
];
